<template>
  <q-btn
    dense
    icon="edit"
    color="primary"
    @click="dialog = true"
    :label="label"
    class="q-mt-md q-pa-sm text-weight-bold"
  />
  <q-dialog v-model="dialog" full-width>
    <q-card>
      <q-card-section class="row items-center q-py-xs bg-primary text-white">
        <div class="text-h5">Edit component settings</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>
      <q-card-section>
        <div v-for="(value, key) in schema" :key="key">
          <div class="q-mb-md">
            <q-btn
              v-if="key > 1 && value.name != 'Overview'"
              icon="expand_less"
              @click="schema = changeOrder(key, '-')"
              dense
              color="primary"
              class="q-mx-xs"
            />
            <q-btn
              v-if="
                value.name != 'Overview' &&
                key < Object.keys(settings).length - 1
              "
              icon="expand_more"
              @click="schema = changeOrder(key, '+')"
              dense
              color="primary"
              class="q-mx-xs"
            />
            <div v-if="value.name == 'Overview'" class="text-red bg-red-1">
              <div class="text-h5 q-py-sm text-center">
                Overview cannot be repositioned
              </div>
            </div>
          </div>
          <p class="q-my-sm">
            <span class="keys text-weight-bolder"> {{ key }} </span>: {
          </p>
          <div class="q-pl-lg">
            <p>
              <span class="keys">"name"</span>:
              <span class="value">"{{ value.name }}"</span>
            </p>
            <p>
              <span class="keys">"prefix"</span>:
              <span class="value">"{{ value.prefix }}"</span>
            </p>
            <div v-if="value.stepper">
              <p><span class="keys">"stepper"</span>: {</p>
              <div
                v-for="(data, label) in value.stepper"
                :key="label"
                class="q-ml-md row justify-start"
              >
                <div v-if="data.input" class="q-py-none row items-center">
                  <SubComponentValue
                    :label="label"
                    :value="data"
                    :settingsValue="
                      settingsProxy[getKey(value.name)]['stepper'][label]
                    "
                    @updateComponentSettings="
                      updateSettings(
                        $event,
                        getKey(value.name),
                        'stepper',
                        label
                      )
                    "
                  />
                </div>
                <div v-if="!data.input" class="q-py-none row items-center">
                  <SubComponentObject
                    :label="label"
                    :value="data"
                    :settingsValue="
                      settingsProxy[getKey(value.name)]['stepper'][label]
                    "
                    @updateComponentSettings="
                      updateSettings(
                        $event,
                        getKey(value.name),
                        'stepper',
                        label
                      )
                    "
                  />
                </div>
              </div>
              <p>},</p>
            </div>
            <div>
              <p><span class="keys">"settings"</span>: {</p>
              <div
                v-for="(data, label) in value.settings"
                :key="label"
                class="q-ml-md row justify-start"
              >
                <div v-if="data.input" class="q-py-none row items-center">
                  <SubComponentValue
                    :label="label"
                    :value="data"
                    :settingsValue="
                      settingsProxy[getKey(value.name)]['settings'][label]
                    "
                    @updateComponentSettings="
                      updateSettings(
                        $event,
                        getKey(value.name),
                        'settings',
                        label
                      )
                    "
                  />
                </div>
                <div v-if="!data.input">
                  <SubComponentObject
                    :label="label"
                    :value="data"
                    :settingsValue="
                      settingsProxy[getKey(value.name)]['settings'][label]
                    "
                    @updateComponentSettings="
                      updateSettings(
                        $event,
                        getKey(value.name),
                        'settings',
                        label
                      )
                    "
                  />
                </div>
              </div>
              <p>},</p>
            </div>
            <div>
              <p><span class="keys">"child_component_settings"</span>: {</p>
              <div
                v-for="(data, label) in value.child_component_settings"
                :key="label"
                class="q-ml-md row justify-start"
              >
                <div v-if="data.input" class="q-py-none row items-center">
                  <SubComponentValue
                    :label="label"
                    :value="data"
                    :settingsValue="
                      settingsProxy[getKey(value.name)][
                        'child_component_settings'
                      ][label]
                    "
                    @updateComponentSettings="
                      updateSettings(
                        $event,
                        getKey(value.name),
                        'child_component_settings',
                        label
                      )
                    "
                  />
                </div>
                <div v-if="!data.input" class="q-py-none">
                  <SubComponentObject
                    :label="label"
                    :value="data"
                    :settingsValue="
                      settingsProxy[getKey(value.name)][
                        'child_component_settings'
                      ][label]
                    "
                    @updateComponentSettings="
                      updateSettings(
                        $event,
                        getKey(value.name),
                        'child_component_settings',
                        label
                      )
                    "
                  />
                </div>
              </div>
              <p>},</p>
            </div>
          </div>
          <p>}</p>
          <q-separator class="q-my-md" color="black" />
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          class="text-weight-bold"
          label="Cancel"
          color="primary"
          v-close-popup
        />
        <q-btn
          flat
          class="text-weight-bold"
          label="Save"
          color="primary"
          @click="saveSettings()"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { computed, defineEmits, ref } from "vue";
import SubComponentObject from "./ComponentSettings/SubComponentObject.vue";
import SubComponentValue from "./ComponentSettings/SubComponentValue.vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  label: String,
  value: String,
});

// eslint-disable-next-line no-unused-vars
const emits = defineEmits(["updateRowRef"]);

const dialog = ref(false);
const newKey = ref(null);
const currentschema = ref({});
const rememberValue = ref({});
const settingsProxy = ref({});
const schema = ref({
  1: {
    // move down / up
    name: "Offers", // vast
    prefix: "/MultiOffer", // vast
    stepper: {
      icon: {
        input: "Input",
        settings: {
          number: false,
          id: false,
        },
      },
      title: {
        input: "Input",
        settings: {
          number: false,
          id: false,
        },
      },
    },
    settings: {
      offer: {
        input: "Toggle",
        settings: {
          trueValue: true,
          falseValue: false,
        },
      },
      product_selection: {
        // dropdown
        input: "Select",
        settings: {
          options: ["List", "Panel"],
          multiple: false,
        },
      },
      nextStatus: {
        input: "Input",
        settings: {
          number: true,
          id: false,
        },
      },
      prevStatus: {
        input: "Input",
        settings: {
          number: true,
          id: false,
        },
      },
    },
    child_component_settings: {
      StartDate: {
        input: "Toggle",
        settings: {
          trueValue: true,
          falseValue: false,
        },
      },
    },
  },
  2: {
    // move down / up
    name: "ProspectInformation", //vast
    prefix: "", // vast
    stepper: {
      icon: {
        input: "Input",
        settings: {
          number: false,
          id: false,
        },
      }, // input
      title: {
        input: "Input",
        settings: {
          number: false,
          id: false,
        },
      }, // input
    },
    settings: {
      verify_offer: {
        input: "Toggle",
        settings: {
          trueValue: true,
          falseValue: false,
        },
      },
      nextStatus: {
        input: "Input",
        settings: {
          number: true,
          id: false,
        },
      },
      prevStatus: {
        input: "Input",
        settings: {
          number: true,
          id: false,
        },
      },
    },
    child_component_settings: {
      Adress: {
        enabled: {
          input: "Toggle",
          settings: {
            trueValue: true,
            falseValue: false,
          },
        },
        readonly: {
          input: "Toggle",
          settings: {
            trueValue: true,
            falseValue: false,
          },
        },
      },
      Contact: {
        enabled: {
          input: "Toggle",
          settings: {
            trueValue: true,
            falseValue: false,
          },
        },
        newsletter: {
          default: {
            // dropdown
            input: "Select",
            settings: {
              options: ["Ja", "Nee"],
              multiple: false,
            },
          },
          enabled: {
            input: "Toggle",
            settings: {
              trueValue: true,
              falseValue: false,
            },
          },
        },
        digital_invoice: {
          enabled: {
            input: "Toggle",
            settings: {
              trueValue: true,
              falseValue: false,
            },
          },
        },
      },
      Payment: {
        enabled: {
          input: "Toggle",
          settings: {
            trueValue: true,
            falseValue: false,
          },
        },
        customerNumber: {
          enabled: {
            input: "Toggle",
            settings: {
              trueValue: true,
              falseValue: false,
            },
          },
        },
      },
      Personal: {
        enabled: {
          input: "Toggle",
          settings: {
            trueValue: true,
            falseValue: false,
          },
        },
      },
      Birthdate: {
        enabled: {
          input: "Toggle",
          settings: {
            trueValue: true,
            falseValue: false,
          },
        },
      },
      CurrentSituation: {
        enabled: {
          input: "Toggle",
          settings: {
            trueValue: true,
            falseValue: false,
          },
        },
      },
    },
  },
  3: {
    // move down / up
    name: "Voicelog", // vast
    prefix: "", // vast
    stepper: {
      icon: {
        input: "Input",
        settings: {
          number: false,
          id: false,
        },
      },
      title: {
        input: "Input",
        settings: {
          number: false,
          id: false,
        },
      },
    },
    settings: {
      timer: {
        input: "Input",
        settings: {
          number: true,
          id: false,
        },
      },
      nextStatus: {
        input: "Input",
        settings: {
          number: true,
          id: false,
        },
      },
      prevStatus: {
        input: "Input",
        settings: {
          number: true,
          id: false,
        },
      },
      useTemplateEngine: {
        input: "Toggle",
        settings: {
          trueValue: true,
          falseValue: false,
        },
      },
    },
    child_component_settings: {},
  },
  4: {
    // move down / up
    name: "Preview", // vast
    prefix: "", // vast
    stepper: {
      icon: {
        input: "Input",
        settings: {
          number: false,
          id: false,
        },
      },
      title: {
        input: "Input",
        settings: {
          number: false,
          id: false,
        },
      },
    },
    settings: {
      sms: {
        input: "Toggle",
        settings: {
          trueValue: true,
          falseValue: false,
        },
      },
      email: {
        input: "Toggle",
        settings: {
          trueValue: true,
          falseValue: false,
        },
      },
      writeOff: {
        input: "Toggle",
        settings: {
          trueValue: true,
          falseValue: false,
        },
      },
      optinVerification: {
        input: "Toggle",
        settings: {
          trueValue: true,
          falseValue: false,
        },
      },
    },
    change_order_step: {
      input: "Input",
      settings: {
        number: true,
        id: false,
      },
    },
    child_component_settings: {},
  },
  5: {
    // vast
    name: "Overview", // vast
    prefix: "/MultiOffer", // vast
    settings: {}, // vast
    child_component_settings: {}, // vast
  },
});

const settings = computed({
  get() {
    return JSON.parse(props.value);
  },
  set(val) {
    emits("updateRowRef", JSON.stringify(val));
  },
});

settingsProxy.value = settings.value;

function changeOrder(key, moveTo) {
  dialog.value = false;
  if (moveTo == "+") {
    newKey.value = parseInt(key) + 1;
  } else if (moveTo == "-") {
    newKey.value = key - 1;
  }
  currentschema.value = schema.value;
  rememberValue.value = currentschema.value[newKey.value];
  currentschema.value[newKey.value] = currentschema.value[key];
  currentschema.value[key] = rememberValue.value;

  rememberValue.value = settingsProxy.value[newKey.value];
  settingsProxy.value[newKey.value] = settingsProxy.value[key];
  settingsProxy.value[key] = rememberValue.value;

  dialog.value = true;
  return currentschema.value;
}

function getKey(name) {
  const result = Object.keys(settings.value).find(
    (key) => settings.value[key].name === name
  );
  return result;
}

function updateSettings(value, key, objectName, keyName) {
  dialog.value = false;

  settingsProxy.value[key][objectName][keyName] = value;

  dialog.value = true;
}

function saveSettings() {
  settings.value = settingsProxy.value;
}
</script>
<style lang="sass">
p
  margin: 0!important
.q-item
  min-width: 30%
.keys
  color: #8f44ff
.value
  color: #7e0000
</style>
