<template>
  <div class="q-pa-xs">
    <span class="keys">"{{ label }}"</span>:
  </div>
  <div class="">
    <Toggle
      v-if="value.input == 'Toggle'"
      :label="''"
      :value="settings"
      v-bind="value.settings"
      @updateRowRef="settings = $event"
    />
    <Select
      v-if="value.input == 'Select'"
      :label="''"
      :value="settings"
      v-bind="value.settings"
      @updateRowRef="settings = $event"
      outlined
    />
    <Input
      v-if="value.input == 'Input'"
      style="width: 300px"
      :label="''"
      :value="settings"
      v-bind="value.settings"
      @updateRowRef="settings = $event"
    />
  </div>
</template>

<script setup>
import { computed, defineEmits } from "vue";
import Toggle from "../Toggle.vue";
import Select from "../Select.vue";
import Input from "../Input.vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  label: String,
  value: String,
  settingsValue: Object,
});

// eslint-disable-next-line no-unused-vars
const emits = defineEmits(["updateComponentSettings"]);

const settings = computed({
  get() {
    return props.settingsValue;
  },
  set(val) {
    emits("updateComponentSettings", val);
  },
});
</script>
