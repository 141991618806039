import script from "./ComponentSettings.vue?vue&type=script&setup=true&lang=js"
export * from "./ComponentSettings.vue?vue&type=script&setup=true&lang=js"

import "./ComponentSettings.vue?vue&type=style&index=0&id=21193ac7&lang=sass"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QItem from 'quasar/src/components/item/QItem.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QDialog,QCard,QCardSection,QSpace,QSeparator,QCardActions,QItem});qInstall(script, 'directives', {ClosePopup});
